<template>
  <div id="app" :class="{'body-scroll': scrolled && this.$route.path !== '/'}">
    <header :class="{'nav-scroll': scrolled}" v-if="checkIndex()">
      <Navigation :scrolled="scrolled" />
    </header>
    <router-view/>
    <Footer/>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

header {
  border-bottom: 1px solid $border-grey;
}

.nav-scroll{
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}

.body-scroll{
  padding-top: 92px;
}

@media screen and (max-width: 700px){
  .body-scroll{
    padding-top: 86px;
  }
}
</style>
<script>
import "@/assets/scss/styles.scss";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  components: {
    Navigation,
    Footer
  },
  data() {
    return {
      scrolled: false,
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    checkIndex: function () {
      if(this.$route.path === '/' && !this.scrolled){
        return false
      } else {
        return true
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
