<template>
  <nav :class="{'nav-index': this.$route.path === '/' && !scrolled}">
    <div class="container">
      <router-link to="/">
        <img :src="require('@/assets/images/logo-w.svg')" alt="" v-if="this.$route.path === '/' && !scrolled" class="logo">
        <img :src="require('@/assets/images/logo-y.svg')" alt="" v-if="this.$route.path !== '/' || scrolled" class="logo">
      </router-link>
      <ul>
        <li :class="{'nav-link-active': this.$route.path === '/'}"><router-link to="/">Home</router-link></li>
        <li :class="{'nav-link-active': this.$route.path === '/services'}"><router-link to="/services">Services</router-link></li>
        <li :class="{'nav-link-active': this.$route.path === '/team'}"><router-link to="/team">Team</router-link></li>
        <li :class="{'nav-link-active': this.$route.path === '/contact'}"><router-link to="/contact">Contact</router-link></li>
      </ul>
      <div class="hamburger" @click="overlayState = true">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="overlay" :class="{'overlay-active': overlayState}">
      <div class="close" @click="overlayState = false"></div>
      <ul>
        <li :class="{'nav-link-active': this.$route.path === '/'}" @click="overlayState = false"><router-link to="/">Home</router-link></li>
        <li :class="{'nav-link-active': this.$route.path === '/services'}" @click="overlayState = false"><router-link to="/services">Services</router-link></li>
        <li :class="{'nav-link-active': this.$route.path === '/team'}" @click="overlayState = false"><router-link to="/team">Team</router-link></li>
        <li :class="{'nav-link-active': this.$route.path === '/contact'}" @click="overlayState = false"><router-link to="/contact">Contact</router-link></li>
      </ul>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
nav{
  padding-top: 25px;
  padding-bottom: 25px;
  color: #4A4A4A;
}

nav .container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul li{
  display: inline-block;
  margin-right: 55px;
  font-weight: 500;
  opacity: 0.5;
  transition: .4s ease-in-out;
}

nav ul li{
  &:hover{
    opacity: 1;
    transition: .4s ease-in-out;
  }
}

nav ul li:last-of-type{
  margin-right: 0;
}

.nav-index{
  color: #fff;
}

.nav-link-active{
  opacity: 1;
}

.logo{
  height: 35px;
  width: auto;
}

.hamburger{
  width: 20px;
  height: auto;
  display: none;

  div{
    width: 100%;
    height: 2px;
    margin-bottom: 4px;
    background: #4A4A4A;
  }

  div:last-of-type{
    margin-bottom: 0;
  }
}

.nav-index{
  .hamburger{
    div{
      background: #fff;
    }
  }
}

.overlay{
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 999;
  position: fixed;
  top: 0; left: 0;
  visibility: hidden;
  opacity: 0;
  transition: .4s ease-in-out;

  ul{
    display: block;
    position: relative;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);

    li{
      color: #4A4A4A;
      display: block;
      text-align: center;
      margin: 0;
      margin-bottom: 30px;
      font-size: 25px;
    }

    li:last-of-type{
      margin-bottom: 0;
    }
  }
}

.overlay-active{
  visibility: visible;
  opacity: 1;
  transition: .4s ease-in-out;
}

.hamburger, .close{
  &:hover{
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  .logo{
    height: 30px;
  }

  nav{
    ul{
      display: none;
    }
  }

  .hamburger{
    display: block;
  }
}
</style>
<script>
export default{
  props: ['scrolled'],
  data () {
    return{
      overlayState: false
    }
  }
}
</script>
