<template>
  <div>
    <section id="hero">
      <Navigation/>
      <div>
        <h1>Quality Translations, On Time</h1>
        <p>Professional translation services specializing in English and Thai as well as the Scandinavian languages.</p>
        <router-link to="/services" class="button">Learn More</router-link>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="section-heading">
          <h1>Our services</h1>
        </div>
        <div class="feature">
          <div>
            <img :src="require('@/assets/images/users.svg')" alt="">
            <h3>Consulting</h3>
            <p>We provide Language Consultancy Services for all of your linguistic needs. Contact us to receive a free quote for any type of request.</p>
          </div>
          <div>
            <img :src="require('@/assets/images/message.svg')" alt="">
            <h3>Translation</h3>
            <p>Our highly qualified team includes 18 professional in-house translators, each native to the target language they translate into and with extensive experience in a wide range of fields.</p>
          </div>
          <div>
            <img :src="require('@/assets/images/world.svg')" alt="">
            <h3>Localization</h3>
            <p>Operating in the global market demands that your documents and content is localized to match your target audience’s perceptions and expectations, we assure that the core meaning of the source text is conveyed in a way the end user can relate to.</p>
          </div>
        </div>
      </div>
    </section>
    <section id="team-banner">
      <div class="container">
        <div class="section-heading">
          <h1>Meet our team</h1>
          <p>In our dynamic work environment, we encourage teamwork and motivate staff to share ideas, terminology and research to ensure a high level of consistency. Senior linguists monitor progress and provide regular internal feedback for optimal quality assurance.</p>
        </div>
        <div class="text-center">
          <router-link to="/team" class="button">Let's go</router-link>
        </div>
      </div>
      <div id="team-banner-img">
        <router-link to="/team">
          <div>
            <img :src="require('@/assets/images/management_team.jpg')" alt="">
          </div>
        </router-link>
        <router-link to="/team">
          <div>
            <img :src="require('@/assets/images/linguist_team.jpg')" alt="">
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';

  #hero {
    width: 100%; // height: 450px;
    background: linear-gradient(to top, #25210C, #000);
    position: relative;
    text-align: center;
    color: #fff;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  #hero {
    div {
      width: 100%;
      max-width: 450px;
      position: relative;
      padding-top: 85px;
      padding-bottom: 115px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #hero h1 {
    color: #fff;
    margin-bottom: 5px;
  }

  #hero p {
    color: #fff;
  }

  #hero .button {
    margin-top: 35px;
  }

  @media screen and (max-width: 800px) {
    #hero {
      div {
        padding-top: 55px;
        padding-bottom: 85px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    #hero {
      div {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  #team-banner{
    padding-bottom: 0;
  }

  #team-banner-img{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 50px;
    background: $light-grey;
    line-height: 0;

    *{
      flex: 1;
      flex-basis: 50%;

      img{
        width: 100%;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 900px){
    #team-banner-img{
      display: block;
    }
  }

</style>
<script>
  import Navigation from '@/components/Navigation';

  export default {
    components: {
      Navigation
    },
    metaInfo: {
      title: 'Wallace Solutions',
      titleTemplate: '%s | Home'
    }
  }
</script>
